import nextGetConfig from 'next/config'
import * as s from 'superstruct'

enum Environment {
  production = 'production',
  development = 'development',
  local = 'local',
  test = 'test',
}

const HTMLEmbedConfig = s.object({
  partnerId: s.string(),
  page: s.string(),
})

const PublicRuntimeConfig = s.object({
  environment: s.enums(Object.values(Environment)),
  graphqlApi: s.object({
    url: s.string(),
  }),
  stripeIntegrationApi: s.object({
    url: s.string(),
  }),
  razorpayIntegrationApi: s.object({
    url: s.string(),
  }),
  auth: s.object({
    loginUrl: s.string(),
    signUpUrl: s.string(),
  }),
  website: s.object({
    url: s.string(),
    webUrl: s.string(),
  }),
  cloudflareImages: s.object({
    url: s.string(),
  }),
  lobby: s.object({
    url: s.string(),
  }),
  events: s.object({
    cookieDomain: s.string(),
  }),
  facebook: s.object({
    appId: s.string(),
    pageId: s.string(),
  }),
  integrations: s.object({
    GTM: s.optional(
      s.object({
        ID: s.string(),
      }),
    ),
    tivity: s.object({
      url: s.string(),
    }),
  }),
  webapi: s.object({
    url: s.string(),
  }),
  calendarPageSlider: s.object({
    initialSessionsIntervalStart: s.integer(),
    initialSessionsIntervalEnd: s.integer(),
  }),
  googlePublisherTag: s.object({
    slotId: s.string(),
  }),
  gsuHTMLEmbeds: s.object({
    medicare: HTMLEmbedConfig,
    videos: HTMLEmbedConfig,
    articles: HTMLEmbedConfig,
    helenRecommendations: HTMLEmbedConfig,
  }),
  defaultPartnerId: s.string(),
  orchestrationService: s.object({
    url: s.string(),
  }),
  dialogs: s.object({
    auth: s.object({
      delay: s.number(), // delay in opening auth dialog for unauthenticated users
    }),
  }),
})
type PublicRuntimeConfig = s.Infer<typeof PublicRuntimeConfig>

const ServerRuntimeConfig = s.object({
  environment: s.enums(Object.values(Environment)),
  invalidationToken: s.object({
    aol: s.string(),
  }),
  sitesAllowedToEmbed: s.array(s.object({ embeddingOrgId: s.string(), hosts: s.array(s.string()) })),
})
type ServerRuntimeConfig = s.Infer<typeof ServerRuntimeConfig>

const Config = s.object({
  serverRuntimeConfig: s.optional(ServerRuntimeConfig),
  publicRuntimeConfig: PublicRuntimeConfig,
})
type Config = s.Infer<typeof Config>

let config: Config

export function getConfig(resetSingleton = false): Config {
  if (config && !resetSingleton) {
    return config
  }

  const { serverRuntimeConfig, publicRuntimeConfig } = nextGetConfig()
  config = {
    serverRuntimeConfig: isEmpty(serverRuntimeConfig) ? undefined : serverRuntimeConfig,
    publicRuntimeConfig,
  }

  if (process.env.JEST_WORKER_ID === undefined) {
    console.log('getConfig: config=', config)
  }

  s.assert(config, Config)

  return config
}

function isEmpty(o: Record<string, unknown>): boolean {
  return Boolean(o) && Object.keys(o).length === 0
}
