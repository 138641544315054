import type { ArticleCard, ClubCard, VodClassCard } from '@/shared/api-generated-types'
import * as React from 'react'

export enum MessageType {
  UserFreeText = 'user_free_text',
  UserStaticPrompt = 'user_static_prompt',
  UserDynamicPrompt = 'user_dynamic_prompt',
}

type Dispatch = (action: Action) => void
type ProviderProps = { children: React.ReactNode }
type Action = { type: 'UPDATE'; payload: Partial<State> }
type State = {
  query: string
  messageType: MessageType
  showPrompts: boolean
  relatedArticles: ArticleCard[]
  relatedClasses: VodClassCard[]
  relatedClubs: ClubCard[]
}
interface ContextInterface {
  state: State
  dispatch: Dispatch
}

const Context = React.createContext<ContextInterface | undefined>(undefined)

const initialState: State = {
  query: '',
  messageType: MessageType.UserFreeText,
  showPrompts: true,
  relatedArticles: [],
  relatedClasses: [],
  relatedClubs: [],
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'UPDATE': {
      return { ...state, ...action.payload }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

function Provider({ children }: ProviderProps): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }
  return <Context.Provider value={value}>{children}</Context.Provider>
}
const AiProvider = Provider

function useAi(): ContextInterface {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useAi must be used within a Provider')
  }
  return context
}
export { AiProvider, useAi }
